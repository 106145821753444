/* This stylesheet generated by Transfonter (http://transfonter.org) on May 18, 2016 12:45 PM */

@font-face {
  font-family: 'Geomanist';
  src: url('hinted-Geomanist-Black.eot');
  src:
    url('hinted-Geomanist-Black.eot?#iefix') format('embedded-opentype'),
    url('hinted-Geomanist-Black.woff2') format('woff2'),
    url('hinted-Geomanist-Black.woff') format('woff'),
    url('hinted-Geomanist-Black.ttf') format('truetype'),
    url('hinted-Geomanist-Black.svg#Geomanist-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist Ultra';
  src: url('hinted-Geomanist-Ultra.eot');
  src:
    url('hinted-Geomanist-Ultra.eot?#iefix') format('embedded-opentype'),
    url('hinted-Geomanist-Ultra.woff2') format('woff2'),
    url('hinted-Geomanist-Ultra.woff') format('woff'),
    url('hinted-Geomanist-Ultra.ttf') format('truetype'),
    url('hinted-Geomanist-Ultra.svg#Geomanist-Ultra') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('hinted-Geomanist-Thin.eot');
  src:
    url('hinted-Geomanist-Thin.eot?#iefix') format('embedded-opentype'),
    url('hinted-Geomanist-Thin.woff2') format('woff2'),
    url('hinted-Geomanist-Thin.woff') format('woff'),
    url('hinted-Geomanist-Thin.ttf') format('truetype'),
    url('hinted-Geomanist-Thin.svg#Geomanist-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('hinted-Geomanist-Light.eot');
  src:
    url('hinted-Geomanist-Light.eot?#iefix') format('embedded-opentype'),
    url('hinted-Geomanist-Light.woff2') format('woff2'),
    url('hinted-Geomanist-Light.woff') format('woff'),
    url('hinted-Geomanist-Light.ttf') format('truetype'),
    url('hinted-Geomanist-Light.svg#Geomanist-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('hinted-Geomanist-Regular.eot');
  src:
    url('hinted-Geomanist-Regular.eot?#iefix') format('embedded-opentype'),
    url('hinted-Geomanist-Regular.woff2') format('woff2'),
    url('hinted-Geomanist-Regular.woff') format('woff'),
    url('hinted-Geomanist-Regular.ttf') format('truetype'),
    url('hinted-Geomanist-Regular.svg#Geomanist-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('hinted-Geomanist-ExtraLight.eot');
  src:
    url('hinted-Geomanist-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('hinted-Geomanist-ExtraLight.woff2') format('woff2'),
    url('hinted-Geomanist-ExtraLight.woff') format('woff'),
    url('hinted-Geomanist-ExtraLight.ttf') format('truetype'),
    url('hinted-Geomanist-ExtraLight.svg#Geomanist-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('hinted-Geomanist-Medium.eot');
  src:
    url('hinted-Geomanist-Medium.eot?#iefix') format('embedded-opentype'),
    url('hinted-Geomanist-Medium.woff2') format('woff2'),
    url('hinted-Geomanist-Medium.woff') format('woff'),
    url('hinted-Geomanist-Medium.ttf') format('truetype'),
    url('hinted-Geomanist-Medium.svg#Geomanist-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Geomanist';
  src: url('hinted-Geomanist-Bold.eot');
  src:
    url('hinted-Geomanist-Bold.eot?#iefix') format('embedded-opentype'),
    url('hinted-Geomanist-Bold.woff2') format('woff2'),
    url('hinted-Geomanist-Bold.woff') format('woff'),
    url('hinted-Geomanist-Bold.ttf') format('truetype'),
    url('hinted-Geomanist-Bold.svg#Geomanist-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
